import React from 'react'

export default function SolutionBlog({ id }) {


    const blogData = {
        // 1: {
        //     image1: '/assets/pos1.png',
        //     header1: 'Opening Cash Control Screen',
        //     content1: 'The Opening Cash Control Screen is a crucial part of the Point of Sale (POS) system that helps businesses manage and track the initial cash in the register at the start of a shift or day. This screen ensures that the cash drawer starts with a known amount, which can later be reconciled at the end of the day to ensure accuracy and prevent discrepancies.',
        //     image2: '/assets/pos2.png',
        //     header2: 'Product Screen and Cart',
        //     content2: 'The Opening Cash Control Screen is a crucial part of the Point of Sale (POS) system that helps businesses manage and track the initial cash in the register at the start of a shift or day. This screen ensures that the cash drawer starts with a known amount, which can later be reconciled at the end of the day to ensure accuracy and prevent discrepancies.',
        //     image3: '/assets/pos3.png',
        //     header3: 'Payment Screen',
        //     content3: 'The Opening Cash Control Screen is a crucial part of the Point of Sale (POS) system that helps businesses manage and track the initial cash in the register at the start of a shift or day. This screen ensures that the cash drawer starts with a known amount, which can later be reconciled at the end of the day to ensure accuracy and prevent discrepancies.',
        //     image4: '/assets/pos4.png',
        //     header4: 'Bill Screen',
        //     content4: 'The Opening Cash Control Screen is a crucial part of the Point of Sale (POS) system that helps businesses manage and track the initial cash in the register at the start of a shift or day. This screen ensures that the cash drawer starts with a known amount, which can later be reconciled at the end of the day to ensure accuracy and prevent discrepancies.',
        // },
        2: {
            image1: '/assets/ecom1.jpeg',
            header1:'Home Page & Product Page',
            content1: 'The Opening Cash Control Screen is a crucial part of the Point of Sale (POS) system that helps businesses manage and track the initial cash in the register at the start of a shift or day. This screen ensures that the cash drawer starts with a known amount, which can later be reconciled at the end of the day to ensure accuracy and prevent discrepancies.',
            image2: '/assets/ecom2.jpeg',
            header2:'Single Product View Page',
            content2: 'The Opening Cash Control Screen is a crucial part of the Point of Sale (POS) system that helps businesses manage and track the initial cash in the register at the start of a shift or day. This screen ensures that the cash drawer starts with a known amount, which can later be reconciled at the end of the day to ensure accuracy and prevent discrepancies.',
            image3: '/assets/ecom3.jpeg',
            header3: 'Cart Page & Payment Integration',
            content3: 'The Opening Cash Control Screen is a crucial part of the Point of Sale (POS) system that helps businesses manage and track the initial cash in the register at the start of a shift or day. This screen ensures that the cash drawer starts with a known amount, which can later be reconciled at the end of the day to ensure accuracy and prevent discrepancies.',
            image4: '/assets/ecom4.jpeg',
            header4: 'Admin Order Controls',
            content4: 'The Opening Cash Control Screen is a crucial part of the Point of Sale (POS) system that helps businesses manage and track the initial cash in the register at the start of a shift or day. This screen ensures that the cash drawer starts with a known amount, which can later be reconciled at the end of the day to ensure accuracy and prevent discrepancies.',
        },
        3: {
            image1: '/assets/pos1.png',
            header1: 'Opening Cash Control Screen',
            content1: 'The Opening Cash Control Screen is a crucial part of the Point of Sale (POS) system that helps businesses manage and track the initial cash in the register at the start of a shift or day. This screen ensures that the cash drawer starts with a known amount, which can later be reconciled at the end of the day to ensure accuracy and prevent discrepancies.',
            image2: '/assets/pos2.png',
            header2: 'Product Screen and Cart',
            content2: 'Allows users to search for and select products to add to the transaction.Displays products in a list or grid, with key information like name, price, SKU, and available quantity.Provides a search bar or filter options to quickly find products by name, category, SKU, or barcode.Shows product images (if available) for visual identification',
            image3: '/assets/pos3.png',
            header3: 'Payment Screen',
            content3: 'Displays the total amount due, including taxes, discounts, and additional fees. Provides multiple payment options such as cash, credit/debit cards, bank. Allows users to split the payment across multiple methods (e.g., part cash, part card). For cash payments, calculates and displays the change due based on the amount tendered by the customer.',
            image4: '/assets/pos4.png',
            header4: 'Bill Screen',
            content4: 'Displays a complete summary of the transaction, including all items purchased, their prices, and quantities. Shows detailed information about applied discounts, taxes, and any promotional offers used. Includes the subtotal, tax breakdown, and the final total amount due or paid. Lists the payment methods used (e.g., cash, card, mobile wallet, or store credit).',
        },
        6: {
            image1: '/assets/hr1.png',
            header1: 'Dashboard Overview',
            content1: 'Gain a comprehensive overview of all key metrics and operational insights at a glance. The dashboard provides real-time data visualizations, helping businesses track performance, identify trends, and make informed decisions effectively.',
            image2: '/assets/hr2.png',
            header2: 'Employee Management',
            content2: 'Efficiently manage your workforce with tools for tracking employee details, work hours, and performance. Simplify payroll, monitor attendance, and ensure seamless communication across your team.',
            image3: '/assets/hr3.png',
            header3: 'Students Management',
            content3: 'Streamline the management of student records, attendance, and progress tracking. Empower educational institutions with tools to monitor performance, manage admissions, and ensure effective communication with students and parents.',      
            image4: '/assets/hr4.png',
            header4: 'Attendance Management',
            content4: 'Track and manage attendance with precision. Automate attendance logging, monitor punctuality, and ensure compliance with company policies to maintain a disciplined and organized workforce.',
        }
        
    }

    const selectedBlog = blogData[id];

    if (!selectedBlog) {
        return <></>;
    }

    return (
        <div className="grid gap-x-8 gap-y-10 md:mt-28 mt-10 md:grid-cols-2">
            {Object.keys(selectedBlog)
                .filter((key) => key.startsWith('image'))
                .map((imageKey, index) => (
                    <div
                        key={imageKey}
                        className="p-4 flex flex-col items-center bg-gray-500 shadow-md rounded-lg"
                    >
                        <img
                            src={selectedBlog[imageKey]}
                            alt={`Content ${index + 1}`}
                            className="w-full h-48 object-cover mb-4 rounded-md"
                        />
                        <h1 className='mb-2 text-lg font-light'>{selectedBlog[`header${index+1}`]}</h1>
                        <p className="text-gray-300 text-justify font-light">{selectedBlog[`content${index + 1}`]}</p>
                    </div>
                ))}
        </div>

    );
}