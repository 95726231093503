// import logo from './logo.svg';
import { Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Home from './pages/home/home';
import Navbar from './pages/home/navbar';
import About from './pages/about/About';
import Services from './pages/services/Services';
import Solutions from './pages/solutions/Solutions';
import { useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SingleSolution from './pages/solutions/solDetails';
import ContactUs from './pages/dropdown/contactus';
import Blog from './pages/dropdown/blog';
import FaQs from './pages/dropdown/faQs';
import Support from './pages/dropdown/support';
import Careers from './pages/dropdown/careers';
gsap.registerPlugin(ScrollTrigger);


function App() {
  useEffect(() => {
    // GSAP Animation (unchanged)
    gsap.to(".box2", {
      y: 400,
      left: 0,
      rotation: 360,
      duration: 15,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
      scrollTrigger: {
        trigger: ".box2",
        start: "top center",
      },
    });

    // Cursor Movement
    const cursors = document.querySelectorAll(".cursor");
    const texts = document.querySelectorAll(".text");
    const updateCursorPosition = (e) => {
      let x = e.pageX;
      let y = e.pageY;
      cursors.forEach(el => {
        el.style.left = `${x}px`;
        el.style.top = `${y}px`;
      });
    };

    texts.forEach(text => {
      text.addEventListener("mouseenter", () => {
        cursors.forEach(el => el.classList.add("hover"));
      });
      text.addEventListener("mouseleave", () => {
        cursors.forEach(el => el.classList.remove("hover"));
      });
    });

    window.addEventListener("mousemove", updateCursorPosition);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("mousemove", updateCursorPosition);
      texts.forEach(text => {
        text.removeEventListener("mouseenter", () => { });
        text.removeEventListener("mouseleave", () => { });
      });
    };
  }, []);
  return (
    <div className="App bg-black relative overflow-hidden">
      <div className="box2 bg-blue-700 w-[200px] h-[200px] fixed top-0 right-0 z-[150] rounded-full blur-[200px]"></div>
      <div className="cursor outer w-[20px] h-[20px] rounded-full absolute z-[150]" style={{ border: '1px solid #f7f7f770', transform: 'translate(-50%, -50%)', pointerEvents: 'none' }}></div>
      <div className="cursor inner w-[20px] h-[20px] rounded-full bg-[#f7f7f720] absolute z-40" style={{ transform: 'translate(-50%, -50%)', pointerEvents: 'none', transition: '250ms ease-out' }}></div>
      <Navbar />
      <Routes>
        {/* Redirect "/" to "/home" */}
        <Route path="/" element={<Navigate to="/home" replace />} />

        {/* Define your actual routes */}
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/solutions/:id" element={<SingleSolution />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/faQs" element={<FaQs />} />
        <Route path="/support" element={<Support />} />

        {/* Catch-all route for unavailable paths, redirecting to "/home" */}
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </div>
  );
}

export default App;
