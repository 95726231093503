import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../home/Footer';
import { IconAssembly, IconChartBubble, IconPackages } from '@tabler/icons-react';
import SolutionBlog from './sol_blog';

export default function SingleSolution() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isImageLoaded, setIsImageLoaded] = useState(false); // State for image loading

  const solutionsData = {
    1: {
      header: "Custom ERP Implementations",
      headContent: "Our Custom ERP Implementation Solution helps businesses automate and manage their key processes with ERP systems that are tailored to their specific needs. This solution includes",
      box1head: "Module Customization",
      box1content: "Choose from a variety of ERP modules such as inventory management, sales, procurement, and financials to create a system that fits your business perfectly",
      box2head: "Seamless Integration",
      box2content: "Our ERP solutions integrate with your existing tools, ensuring smooth data flow and enhanced productivity",
      box3head: "Scalable Design",
      box3content: "Whether you're a small business or an enterprise, our ERP implementations are built to scale as your business grows",
      image: "/assets/errp.jpg"
    },
    2: {
      header: "E-Commerce Ecosystem Solutions",
      headContent: "Our E-Commerce Ecosystem Solution helps businesses not only sell products but also manage the entire lifecycle of online transactions. This solution includes",
      box1head: "Product Management",
      box1content: "Create an engaging, intuitive online store that integrates seamlessly with your inventory and payment systems for smooth product sales.",
      box2head: "Order Management",
      box2content: "Sell across multiple channels with centralized data that tracks every order, from your website to third-party platforms.",
      box3head: "Payment Management",
      box3content: "Securely process payments while integrating marketing tools, CRM, and analytics to enhance customer insights and sales.",
      image: "/assets/isometric.jpg"
    },
    3: {
      "header": "POS Solutions",
      "headContent": "For businesses that operate in dynamic environments, our Mobile-First Business Solutions are designed to ensure your teams can stay productive no matter where they are. This solution includes",
      "box1head": "Field Sales Applications",
      "box1content": "Equip your sales team with a mobile app to manage orders, track inventory, and handle customer relations on the go.",
      "box2head": "Opening Cash Control",
      "box2content": "Manage the starting cash in the register with a screen that prompts for the total amount and provides a detailed breakdown by denomination.",
      "box3head": "Product Screen and Cart",
      "box3content": "Search, select, and add products to the cart, adjust quantities, apply discounts, and view product details and stock levels.",
      "image": "/assets/pos_Thumbnail.jpg"
    },
    4: {
      header: "Complete Digital Transformation",
      headContent: "Our Digital Transformation Solution is designed for businesses looking to modernize their operations and maximize efficiency across all departments. This solution includes",
      box1head: "ERP System Integration",
      box1content: "We seamlessly implement ERP systems that manage everything from finance to sales to inventory, giving your business complete control over operations",
      box2head: "E-commerce & Public Website Development",
      box2content: "Enhance your online presence and sales channels with a fully integrated e-commerce platform and a professional public-facing website",
      box3head: "Mobile Applications",
      box3content: "Equip your sales force, managers, and employees with custom mobile applications that provide real-time data and streamline workflows, whether in the office.",
      image: "/assets/internet.jpg"
    },
    5: {
      header: "Mobile-First Business Solutions",
      headContent: "For businesses that operate in dynamic environments, our Mobile-First Business Solutions are designed to ensure your teams can stay productive no matter where they are. This solution includes",
      box1head: "Field Sales Applications",
      box1content: "Equip your sales team with a powerful mobile app to manage orders, track inventory, and handle customer relations on the go",
      box2head: "HRMS Mobile Integration",
      box2content: "Manage employee attendance, leave applications, and payroll directly from mobile devices, ensuring flexibility for both managers and staff",
      box3head: "Custom Mobile Dashboards",
      box3content: "Provide executives and management with mobile dashboards that deliver real-time insights and reports, allowing for quicker decision-making and oversight",
      image: "/assets/mobileapp.jpg"
    },
    6: {
      header: "HR & Payroll Solutions",
      headContent: "Our HR & Payroll Solutions streamline human resource management and payroll processes for businesses of all sizes. This solution includes",
      box1head: "Employee Management",
      box1content: "Manage employee data, attendance, leave, and performance evaluations in one centralized system",
      box2head: "Payroll Processing",
      box2content: "Automate payroll calculations, tax deductions, and generate pay slips with ease tax deductions, and generate pay slips with ease",
      box3head: "Compliance Management",
      box3content: "Stay up-to-date with labor laws and regulations, ensuring your business remains compliant your business remains compliant",
      image: "/assets/hrms1.png"
    }
  };

  const truncateText = (text, maxWords) => {
    const words = text.split(' ');
    return words.length > maxWords
      ? words.slice(0, maxWords).join(' ') + '...'
      : text;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const solutionData = solutionsData[id];
    if (solutionData) {
      setData(solutionData);
    }
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <div className='text-white min-h-screen pt-[90px] px-7 md:px-20'>
      <div className='lg:px-52'>
        <h1 className='font-lato font-light text-xl sm:text-2xl lg:text-3xl text-center'>{data.header}</h1>
        <p className='md:mt-6 mt-4 font-light text-xs md:text-base text-justify sm:text-center font-josefin text-gray-400'>{data.headContent}</p>
      </div>
      <div className='head flex mt-10 mb-16 flex-col-reverse mmd:flex-row gap-y-10'>
        <div className='lg:w-[60%] w-full flex flex-col gap-7 items-end'>
          <div className='flex rounded-xl overflow-hidden w-full'>
            <div className='min-w-[100px] flex-shrink-0 bg-white text-black flex justify-center items-center'>
              <IconAssembly stroke={2} className='w-3/4 h-3/4' />
            </div>
            <div className='py-2 px-3 flex-grow bg-gray-500 flex flex-col gap-y-3'>
              <h1 className={`text-left font-normal font-josefin ${isMobile ? 'truncate-boxhead' : ''}`}>
                {isMobile ? truncateText(data.box1head, 3) : data.box1head}
              </h1>
              <p className='text-left sm:text-sm text-xs font-light font-josefin text-gray-300 text'>
                {isMobile ? truncateText(data.box1content, 12) : data.box1content}
              </p>
            </div>
          </div>

          {/* Additional Boxes */}
          <div className='flex rounded-xl overflow-hidden w-full'>
            <div className='min-w-[100px] flex-shrink-0 bg-white text-black flex justify-center items-center'>
              <IconChartBubble stroke={2} className='w-3/4 h-3/4' />
            </div>
            <div className='py-2 px-3 flex-grow bg-gray-500 flex flex-col gap-y-3'>
              <h1 className='text-left font-normal font-josefin'>
                {isMobile ? truncateText(data.box2head, 3) : data.box2head}
              </h1>
              <p className='text-left sm:text-sm text-xs font-light font-josefin text-gray-300'>
                {isMobile ? truncateText(data.box2content, 12) : data.box2content}
              </p>
            </div>
          </div>

          <div className='flex rounded-xl overflow-hidden w-full'>
            <div className='min-w-[100px] flex-shrink-0 bg-white text-black flex justify-center items-center'>
              <IconPackages stroke={2} className='w-3/4 h-3/4' />
            </div>
            <div className='py-2 px-3 flex-grow bg-gray-500 flex flex-col gap-y-3'>
              <h1 className='text-left font-normal font-josefin'>
                {isMobile ? truncateText(data.box3head, 3) : data.box3head}
              </h1>
              <p className='text-left sm:text-sm text-xs font-light font-josefin text-gray-300'>
                {isMobile ? truncateText(data.box3content, 12) : data.box3content}
              </p>
            </div>
          </div>
        </div>

        <div className='lg:w-[40%] w-full flex items-center justify-center'>
          {!isImageLoaded && (
            <div className="animate-pulse bg-gray-200 h-[300px] w-full max-w-[330px] md:w-[450px] rounded-xl"></div>
          )}
          <img
            src={data.image}
            alt={data.header}
            className={`rounded-xl mmd:max-w-[330px] md:w-[450px] ${isImageLoaded ? 'block' : 'hidden'}`}
            onLoad={() => setIsImageLoaded(true)}
            style={{ objectFit: 'cover' }}
          />
        </div>
      </div>
      <SolutionBlog id={id} />

      <Footer />
    </div>
  );
}
