import React from "react";
// import gsap from "gsap"
// import {useGSAP} from '@gsap/react'
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// gsap.registerPlugin(useGSAP)
// gsap.registerPlugin(ScrollTrigger)
export default function HomeContent() {
    // useGSAP(()=>{
    //     gsap.to(".box1",{
    //         scrollTrigger:{
    //             trigger:'.box1',
    //             markers:true,
    //             start:'0px 100%',
    //             // end: '300px',
    //             scrub:true,
    //             // toggleActions:'restart pause reverse none'
    //         },
    //         rotation:'360',
    //         duration:3,
    //         // repeat:-1,
    //         ease:'none'
    //     })
    // })
    // useGSAP(()=>{
    //   gsap.to(".box2", {
    //     scrollTrigger: {
    //       trigger: '.box2',
    //       markers: true,
    //       start: 'top center',
    //       end: 'top 100px',
    //       scrub: true,
    //     },
    //     x: 400,
    //     // bottom: 0,
    //     duration: 3,
    //     ease: 'none',
    //   });
    // })
  return (
    <>
      <div className="md:py-36 py-28 bg-black text-white w-full flex justify-center items-center relative overflow-hidden flex-wrap gap-y-32">
        {/* <div className="box1 bg-blue-700 w-[100px] h-[100px] absolute right-10 top-[105vh]"></div>
        <div className="box2 bg-blue-700 w-[200px] h-[200px] absolute top-0 right-0 rounded-full z-0"></div> */}
        <div className="headerside md:w-1/2 flex flex-col gap-2 ps-7 md:ps-24 min-w-[300px] w-full">
            <p className="text text-blue-400 md:text-xl text-sm text-left font-extralight sm:font-light">Connecting You To Tomorrrow</p>
            <h1 className="text font-light md:text-4xl lg:text-5xl text-3xl text-left leading-tight">Innovative Solutions <br /> For Progressive<br />Success</h1>
        </div>
        <div className="imageSide md:w-1/2 flex justify-center md:justify-start items-center px-7 min-w-[300px] w-full">
        <img src="/assets/mianphoto1.png" alt="mainimage" 
        className="max-w-[500px] min-w-[250px]"/>
        </div>
      </div>
    </>
  );
}
