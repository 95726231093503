import React, { useEffect } from 'react'
import JoinNow from '../home/joinNow'
import Footer from '../home/Footer'

export default function ContactUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className='min-h-screen pt-[100px] text-white'>
            <h1 className='font-lato font-light text-xl sm:text-3xl lg:text-4xl'>Connect with Us</h1>
            <JoinNow />
            <div className='px-6 md:px-20 md:mt-20 mt-10'>
                <Footer />
            </div>

        </div>
    )
}
