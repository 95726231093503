import React, { useState, useEffect } from 'react';
import { IconMail, IconPhonePlus } from '@tabler/icons-react';

export default function JoinNow() {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [toast, setToast] = useState({ 
        message: '', 
        type: '', 
        visible: false, 
        isLeaving: false 
    });

    useEffect(() => {
        let toastTimer;
        let leaveTimer;

        if (toast.visible) {
            // Set timer for starting the leave animation
            toastTimer = setTimeout(() => {
                setToast(prev => ({ ...prev, isLeaving: true }));
            }, 2700); // Start leave animation slightly before hiding

            // Set timer for completely removing the toast
            leaveTimer = setTimeout(() => {
                setToast(prev => ({ ...prev, visible: false, isLeaving: false }));
            }, 3000);
        }

        return () => {
            clearTimeout(toastTimer);
            clearTimeout(leaveTimer);
        };
    }, [toast.visible]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await fetch('https://officialbackend.taqnura.com/contactus/sent-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setToast({ 
                    message: 'Email sent successfully!', 
                    type: 'success', 
                    visible: true, 
                    isLeaving: false 
                });
                setFormData({ name: '', email: '', message: '' });
            } else {
                setToast({ 
                    message: 'Error! Please try again.', 
                    type: 'error', 
                    visible: true, 
                    isLeaving: false 
                });
            }
        } catch (error) {
            setToast({ 
                message: 'An error occurred. Please try again later.', 
                type: 'error', 
                visible: true, 
                isLeaving: false 
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const closeToast = () => {
        setToast(prev => ({ ...prev, isLeaving: true }));
        setTimeout(() => {
            setToast(prev => ({ ...prev, visible: false, isLeaving: false }));
        }, 300);
    };

    return (
        <>
            {/* Animated Toast Notification */}
            {toast.visible && (
                <div
                    className={`
                        max-w-xs bg-white border border-gray-200 rounded-xl shadow-lg 
                        fixed top-5 left-1/2 -translate-x-1/2 z-[1000]
                        ${toast.type === 'success' ? 'bg-teal-500' : 'bg-red-500'}
                        text-white transition-all duration-300 ease-in-out
                        ${toast.isLeaving 
                            ? 'opacity-0 -translate-y-full' 
                            : 'opacity-100 translate-y-0'
                        }
                    `}
                    role="alert"
                    tabIndex="-1"
                    aria-labelledby="toast-label"
                >
                    <div className="flex p-4">
                        <div className="shrink-0 flex justify-center items-center">
                            <svg
                                className="size-4 mt-0.5"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                viewBox="0 0 16 16"
                            >
                                {toast.type === 'success' ? (
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                ) : (
                                    <path d="M8 2a6 6 0 1 1 0 12A6 6 0 0 1 8 2zm-.5 7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1zm0 2a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1z" />
                                )}
                            </svg>
                        </div>
                        <div className="ml-3 flex justify-center items-center">
                            <p id="toast-label" className="text-sm">
                                {toast.message}
                            </p>
                        </div>
                        <button
                            onClick={closeToast}
                            className="mx-4 text-white hover:text-gray-200 focus:outline-none"
                            aria-label="Close"
                        >
                            <span className="text-xl">&times;</span>
                        </button>
                    </div>
                </div>
            )}

            {/* Main Content */}
            <div className="bg-[rgba(217,217,217,0.3)] p-5 md:mx-28 mx-5 md:mt-24 mt-10 flex flex-wrap font-lato rounded-lg border-2 border-gray-500">
                {/* Image Side */}
                <div className="imageSide md:w-1/2 flex justify-center items-center min-w-[200px] w-full">
                    {!isImageLoaded && (
                        <div className="skeleton-loader bg-gray-300 w-[250px] h-[150px] md:w-[350px] md:h-[250px] animate-pulse rounded-lg"></div>
                    )}
                    <img
                        src="/assets/joinPhoto.png"
                        alt="joinphoto"
                        className={`md:max-w-[350px] max-w-[250px] ${isImageLoaded ? 'block' : 'hidden'}`}
                        onLoad={() => setIsImageLoaded(true)}
                    />
                </div>

                {/* Content Side */}
                <div className="contentSide md:w-1/2 text-white flex flex-col gap-3 justify-center items-start min-w-[200px]">
                    <h1 className="font-light text-base md:text-3xl text-left">
                        We are ready to grow your <span className="text-blue-400">Business.</span>
                    </h1>
                    
                    <p className="text-left font-light sm:text-sm text-xs md:pe-8 pe-2">
                        Have questions? Ready to embark on your digital transformation journey? 
                        Let's start building the future of your business together—get in touch with us.
                    </p>
                    
                    <a href="mailto:info@taqnura.com" className="hover:text-blue-400 flex gap-3 text-sm font-light">
                        <IconMail stroke={1} />
                        <span>info@taqnura.com</span>
                    </a>
                    
                    <a href="tel:+918891341818" className="hover:text-blue-400 flex gap-3 text-sm font-light">
                        <IconPhonePlus stroke={1} />
                        <span>+91 8891341818</span>
                    </a>

                    {/* Contact Form */}
                    <form onSubmit={handleSubmit} className="flex flex-col space-y-2 w-full mt-5">
                        <div className="flex space-x-2 w-full">
                            <input
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                className="w-1/2 h-8 bg-transparent border-2 text-sm placeholder:text-gray-400 md:text-base border-slate-500 py-1 px-2"
                                placeholder="Your Name ..."
                                required
                            />
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className="w-1/2 h-8 bg-transparent border-2 text-sm md:text-base placeholder:text-gray-400 border-slate-500 py-1 px-2"
                                placeholder="example@gmail.com"
                                required
                            />
                        </div>
                        
                        <textarea
                            name="message"
                            value={formData.message}
                            onChange={handleInputChange}
                            placeholder="Add message here..."
                            className="h-auto placeholder:text-gray-400 bg-transparent border-2 text-sm md:text-base border-slate-500 py-1 px-2"
                            rows={1}
                            required
                        ></textarea>
                        
                        <button
                            type="submit"
                            disabled={isSubmitting}
                            className="px-2 py-1 bg-blue-700 border-2 border-slate-500 text-sm md:text-base xl:px-5 hover:bg-blue-800 transition-colors duration-300"
                        >
                            {isSubmitting ? 'Sending...' : 'Join Now'}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}