import React, { useState, useEffect } from "react";
import { IconBaselineDensityMedium } from "@tabler/icons-react";
import { Sidebar } from "primereact/sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

export default function Navbar() {
  const [visible, setVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null); // Start with null

  const dropdownItems = [
    { label: "Blog", value: "/blog" },
    { label: "Careers", value: "/careers" },
    { label: "FAQs", value: "/faqs" },
    { label: "Support", value: "/support" },
  ];

  useEffect(() => {
    if (location.pathname !== "/") {
      setSelectedOption(location.pathname);
    }
  }, [location.pathname]);

  const handleOptionClick = (option) => {
    setIsOpen(false);
    navigate(option);
  };

  // const handleDropdownChange = (e) => {
  //   const selectedPath = e.target.value;
  //   setSelectedOption(selectedPath);
  //   navigate(selectedPath);
  // };

  // const isDropdownOptionSelected = dropdownItems.some(
  //   (item) => item.value === selectedOption
  // );

  const customIcons = (
    <React.Fragment>
      <button className="p-sidebar-icon p-link mr-2"></button>
    </React.Fragment>
  );

  const customHeader = (
    <div className="flex align-items-center gap-2">
      <h1 className="text-2xl font-lato font-bold">
        TaQ<span className="text-blue-400">nura.</span>
      </h1>
    </div>
  );

  return (
    <div className="w-full md:h-[70px] h-[50px]  bg-transparent backdrop-blur-sm fixed top-0 text-white z-30 flex justify-between items-center pe-0 md:pe-10 md:ps-0">
      <style>{`
        .slide-in p {
          opacity: 0;
          transform: translateY(-20px);
          animation: slideIn 0.5s ease forwards;
          animation-fill-mode: forwards;
        }

        .slide-in p:nth-child(1) { animation-delay: 0s; }
        .slide-in p:nth-child(2) { animation-delay: 0.3s; }
        .slide-in p:nth-child(3) { animation-delay: 0.6s; }
        .slide-in p:nth-child(4) { animation-delay: 0.9s; }
        .slide-in p:nth-child(5) { animation-delay: 1.2s; }
        .slide-in p:nth-child(6) { animation-delay: 1.5s; }
        .slide-in p:nth-child(7) { animation-delay: 1.8s; }
        .slide-in p:nth-child(8) { animation-delay: 2.1s; }
        .slide-in p:nth-child(9) { animation-delay: 2.4s; }

        @keyframes slideIn {
          0% { opacity: 0; transform: translateY(-20px); }
          100% { opacity: 1; transform: translateY(0); }
        }

        select {
          background-color: transparent;
          color: white;
          border: none;
          padding: 5px;
          font-size: 1rem;
          appearance: none;
        }

       select:focus {
        --tw-ring-color: #1C64F2;
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        border-color: transparent;
        box-shadow: none;
        outline: none;
        }

        option {
          background-color: rgba(0, 0, 0, 0.8);
          color: white;
        }
      `}</style>

      {/* Mobile Version: Below 640px */}
      <div
        className="sm:hidden w-full flex justify-center h-full bg-[rgba(217,217,217,0.4)]"
        style={{ boxShadow: "0px 0px 20px rgba(217, 217, 217, 0.2)" }}
      >
        <button
          className="absolute left-7 md:top-7 top-4 text-blue-400"
          onClick={() => setVisible(true)}
        >
          <IconBaselineDensityMedium
            stroke={2}
            width={20}
            height={20}
            className="w-5 h-5"
          />
        </button>
        <div className="flex justify-center items-center gap-1">
          <img src="/assets/logo.png" alt="" className="w-[30px] h-[30px]" />
          <h1 className="text-2xl font-lato font-bold">
            TaQ<span className="text-blue-400">nura.</span>
          </h1>
        </div>

        <Sidebar
          header={customHeader}
          visible={visible}
          onHide={() => setVisible(false)}
          icons={customIcons}
          className="text backdrop-blur-sm text-gray-300 max-w-[230px] sm:max-w-[280px] z-30"
          style={{
            backgroundColor: "rgba(217, 217, 217, 0.2)",
            boxShadow: "0px 0px 20px rgba(217, 217, 217, 0.2)",
          }}
          transitionOptions={{ duration: 1000, easing: "ease-in-out" }}
        >
          <div className="w-full flex flex-col gap-7 mt-10 slide-in items-end pe-8 font-lato font-light">
            <p>
              <Link
                to="/home"
                onClick={() => handleOptionClick("/home")}
                className={`text ${
                  selectedOption === "/home" ? "text-blue-400" : ""
                }`}
              >
                Home
              </Link>
            </p>
            <p>
              <Link
                to="/about"
                onClick={() => handleOptionClick("/about")}
                className={`text ${
                  selectedOption === "/about" ? "text-blue-400" : ""
                }`}
              >
                About Us
              </Link>
            </p>
            <p>
              <Link
                to="/services"
                onClick={() => handleOptionClick("/services")}
                className={`text ${
                  selectedOption === "/services" ? "text-blue-400" : ""
                }`}
              >
                Services
              </Link>
            </p>
            <p>
              <Link
                to="/solutions"
                onClick={() => handleOptionClick("/solutions")}
                className={`text ${
                  selectedOption === "/solutions" ? "text-blue-400" : ""
                }`}
              >
                Solutions
              </Link>
            </p>
            <p>
              <Link
                to="/contact"
                onClick={() => handleOptionClick("/contact")}
                className={`text ${
                  selectedOption === "/contact" ? "text-blue-400" : ""
                }`}
              >
                Contact Us
              </Link>
            </p>
            {dropdownItems.map((item) => (
              <p key={item.value}>
                <Link
                  to={item.value}
                  onClick={() => handleOptionClick(item.value)}
                  className={`text ${
                    selectedOption === item.value ? "text-blue-400" : ""
                  }`}
                >
                  {item.label}
                </Link>
              </p>
            ))}
          </div>
        </Sidebar>
      </div>

      {/* Desktop Version: Above 640px */}
      <div className="hidden sm:flex w-full justify-between items-center h-full ">
        <div
          className="flex gap-1 justify-start md:px-20 px-5  items-center w-1/3 h-full bg-[rgba(217,217,217,0.5)]"
          style={{ clipPath: "polygon(0 0, 100% 0%, 90% 100%, 0% 100%)" }}
        >
          <img src="/assets/logo.png" alt="" className="w-[40px] h-[40px]" />
          <h1 className="text-2xl font-lato font-bold">
            TaQ<span className="text-blue-400">nura.</span>
          </h1>
        </div>

        <div className="flex space-x-6 md:space-x-12 font-lato font-light pe-5">
          <Link
            to="/home"
            onClick={() => handleOptionClick("/home")}
            className={`hover:text-blue-400 transition duration-300 ${
              selectedOption === "/home" ? "text-blue-400" : ""
            }`}
          >
            Home
          </Link>
          <Link
            to="/about"
            onClick={() => handleOptionClick("/about")}
            className={`hover:text-blue-400 transition duration-300 ${
              selectedOption === "/about" ? "text-blue-400" : ""
            }`}
          >
            About Us
          </Link>
          <Link
            to="/services"
            onClick={() => handleOptionClick("/services")}
            className={`hover:text-blue-400 transition duration-300 ${
              selectedOption === "/services" ? "text-blue-400" : ""
            }`}
          >
            Services
          </Link>
          <Link
            to="/solutions"
            onClick={() => handleOptionClick("/solutions")}
            className={`hover:text-blue-400 transition duration-300 ${
              selectedOption === "/solutions" ? "text-blue-400" : ""
            }`}
          >
            Solutions
          </Link>
          <Link
            to="/contact"
            onClick={() => handleOptionClick("/contact")}
            className={`hover:text-blue-400 transition duration-300 ${
              selectedOption === "/contact" ? "text-blue-400" : ""
            }`}
          >
            Contact Us
          </Link>
          <div className="relative inline-block text-left">
            {/* Dropdown toggle button */}
            <button className="text-white" onClick={() => setIsOpen(!isOpen)}>
              <IconBaselineDensityMedium
                stroke={2}
                width={20}
                height={20}
                className="w-5 h-5"
              />
            </button>

            {/* Dropdown menu */}
            <div
              className={`absolute right-0 mt-2 w-40 bg-gray-800 rounded-lg shadow-lg z-50 transition-all duration-300 ease-out transform ${
                isOpen
                  ? "opacity-100 scale-100"
                  : "opacity-0 scale-95 pointer-events-none"
              }`}
            >
              <ul className="py-1 text-white">
                {dropdownItems.map((item) => (
                  <li key={item.value}>
                    <button
                      onClick={() => handleOptionClick(item.value)}
                      className="block px-4 py-2 w-full text-left hover:bg-gray-700"
                    >
                      {item.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
