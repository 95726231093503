import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

// Register ScrollTrigger plugin with GSAP
gsap.registerPlugin(ScrollTrigger);

export default function BoostCards() {
    useEffect(() => {
        // GSAP animation setup
        gsap.fromTo(".card-1", 
            { x: -300, opacity: 0 }, // Initial state
            {
                x: 0,
                opacity: 1,
                duration: 5,
                scrollTrigger: {
                    trigger: ".card-1",
                    start: "top 100%", // Start the animation when the top of the card is 50% from the top of the viewport
                    end: "bottom 50%", // End the animation when the bottom of the card is 50% from the top of the viewport
                    scrub: true,
                }
            }
        );

        gsap.fromTo(".card-2", 
            { x: 300, opacity: 0 }, // Initial state
            {
                x: 0,
                opacity: 1,
                duration: 5,
                scrollTrigger: {
                    trigger: ".card-2",
                    start: "top 100%",
                    end: "bottom 50%",
                    scrub: true,
                }
            }
        );

        gsap.fromTo(".card-3", 
            { x: -300, opacity: 0 }, // Initial state
            {
                x: 0,
                opacity: 1,
                duration: 2,
                scrollTrigger: {
                    trigger: ".card-3",
                    start: "top 100%",
                    end: "bottom 50%",
                    scrub: true,
                }
            }
        );

        gsap.fromTo(".card-4", 
            { x: 300, opacity: 0 }, // Initial state
            {
                x: 0,
                opacity: 1,
                duration: 2,
                scrollTrigger: {
                    trigger: ".card-4",
                    start: "top 100%",
                    end: "bottom 50%",
                    scrub: true,
                }
            }
        );
    }, []);

    return (
        <div className="text-white font-josefin font-light">
            <h1 className="text-xl md:text-4xl mt-10 mb-5">
                How to boost Your <span className="text-blue-400">Business</span>
            </h1>
            <div className="cardcontainer w-full flex flex-wrap gap-10 md:p-20 p-4 pt-10 md:pt-20 gap-y-14">
                <div className="card-1 md:w-[calc(50%-20px)] w-full bg-[rgba(217,217,217,0.3)] p-5 flex flex-col gap-4 items-start md:pt-10 pt-5 relative rounded-lg">
                    <h1 className="md:text-xl text-base">Set Up ERP In Your Business</h1>
                    <p className="text-left md:pe-20 pe-8 pb-2 text-sm md:text-base">
                        Streamline operations, enhance productivity, and achieve growth with our comprehensive ERP solutions.
                    </p>
                    <div className="absolute bg-black top-[-10px] right-[-10px] h-[60px] w-[100px] rounded-xl">
                        <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/3616b650ba5557bb1d0abfe7c2fc59962e18680a3ccf05641b181d911ea7d3ac?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/3616b650ba5557bb1d0abfe7c2fc59962e18680a3ccf05641b181d911ea7d3ac?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3616b650ba5557bb1d0abfe7c2fc59962e18680a3ccf05641b181d911ea7d3ac?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/3616b650ba5557bb1d0abfe7c2fc59962e18680a3ccf05641b181d911ea7d3ac?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/3616b650ba5557bb1d0abfe7c2fc59962e18680a3ccf05641b181d911ea7d3ac?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/3616b650ba5557bb1d0abfe7c2fc59962e18680a3ccf05641b181d911ea7d3ac?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/3616b650ba5557bb1d0abfe7c2fc59962e18680a3ccf05641b181d911ea7d3ac?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=2000 2000w"
                            className="object-contain self-end aspect-square w-[72px] max-md:mr-2.5 absolute top-[-20px] left-3"
                            alt='logo'
                        />
                    </div>
                </div>
                <div className="card-2 md:w-[calc(50%-20px)] w-full bg-[rgba(217,217,217,0.3)] p-5 flex flex-col gap-4 items-start md:pt-10 pt-5 relative rounded-lg">
                    <h1 className="md:text-xl text-base">Create Your Own Cloud</h1>
                    <p className="text-left md:pe-20 pe-8 pb-2 text-sm md:text-base">
                        Set a secondary sales fee and add social links, a description, profile and banner images, and a description.
                    </p>
                    <div className="absolute bg-black top-[-10px] right-[-10px] h-[60px] w-[100px] rounded-xl">
                        <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/bdfd1ed588dc044a017ef4cf80b4c1863584e2f01f161b649d42ad3c0562ec62?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdfd1ed588dc044a017ef4cf80b4c1863584e2f01f161b649d42ad3c0562ec62?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdfd1ed588dc044a017ef4cf80b4c1863584e2f01f161b649d42ad3c0562ec62?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdfd1ed588dc044a017ef4cf80b4c1863584e2f01f161b649d42ad3c0562ec62?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdfd1ed588dc044a017ef4cf80b4c1863584e2f01f161b649d42ad3c0562ec62?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdfd1ed588dc044a017ef4cf80b4c1863584e2f01f161b649d42ad3c0562ec62?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/bdfd1ed588dc044a017ef4cf80b4c1863584e2f01f161b649d42ad3c0562ec62?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=2000 2000w"
                            className="object-contain self-end aspect-square w-[72px] max-md:mr-2.5 absolute top-[-20px] left-3"
                            alt='logo'
                        />
                    </div>
                </div>
                <div className="card-3 md:w-[calc(50%-20px)] w-full bg-[rgba(217,217,217,0.3)] p-5 flex flex-col gap-4 items-start md:pt-10 pt-5 relative rounded-lg">
                    <h1 className="md:text-xl text-base">Creative AI</h1>
                    <p className="text-left md:pe-20 pe-8 pb-2 text-sm md:text-base">
                        Leverage AI to enhance decision-making, automate processes, and drive innovation in your business operations.
                    </p>
                    <div className="absolute bg-black top-[-10px] right-[-10px] h-[60px] w-[100px] rounded-xl">
                        <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/8f74b49ce880d056ea25add48b3428a2c8e7c2d4d93bbb52859262436517cca3?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f74b49ce880d056ea25add48b3428a2c8e7c2d4d93bbb52859262436517cca3?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f74b49ce880d056ea25add48b3428a2c8e7c2d4d93bbb52859262436517cca3?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f74b49ce880d056ea25add48b3428a2c8e7c2d4d93bbb52859262436517cca3?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f74b49ce880d056ea25add48b3428a2c8e7c2d4d93bbb52859262436517cca3?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f74b49ce880d056ea25add48b3428a2c8e7c2d4d93bbb52859262436517cca3?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/8f74b49ce880d056ea25add48b3428a2c8e7c2d4d93bbb52859262436517cca3?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=2000 2000w"
                            className="object-contain absolute z-0 aspect-square h-[60px] w-[60px] top-[-20px] left-3"
                            alt='logo'
                        />
                    </div>
                </div>
                <div className="card-4 md:w-[calc(50%-20px)] w-full bg-[rgba(217,217,217,0.3)] p-5 flex flex-col gap-4 items-start md:pt-10 pt-5 relative rounded-lg">
                    <h1 className="md:text-xl text-base">Put Them Up for Sale</h1>
                    <p className="text-left md:pe-20 pe-8 pb-2 text-sm md:text-base">
                        Auctions, fixed-price listings, and declining-price listings are all options. You decide how you sell.
                    </p>
                    <div className="absolute bg-black top-[-10px] right-[-10px] h-[60px] w-[100px] rounded-xl">
                        <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/371f126edb8e15cec873472590e8a61f180e7bd72aaed14196340ccc560ee438?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/371f126edb8e15cec873472590e8a61f180e7bd72aaed14196340ccc560ee438?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/371f126edb8e15cec873472590e8a61f180e7bd72aaed14196340ccc560ee438?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/371f126edb8e15cec873472590e8a61f180e7bd72aaed14196340ccc560ee438?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/371f126edb8e15cec873472590e8a61f180e7bd72aaed14196340ccc560ee438?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/371f126edb8e15cec873472590e8a61f180e7bd72aaed14196340ccc560ee438?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/371f126edb8e15cec873472590e8a61f180e7bd72aaed14196340ccc560ee438?placeholderIfAbsent=true&apiKey=24143690a5fe41d3b558637115cff712&width=2000 2000w"
                            className="object-contain self-end aspect-square w-[72px] max-md:mr-2.5 absolute left-3 top-[-20px]"
                            alt='logo'
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
