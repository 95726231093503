import React, { useState, useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Footer from '../home/Footer';
import Background from './aboutBack.jpg'

gsap.registerPlugin(ScrollTrigger);

export default function About() {
  useEffect(() => {
    // Adjusting the parallax effect for the second section
    gsap.to(".sec_portion", {
      yPercent: -50,
      ease: "none",
      scrollTrigger: {
        trigger: ".sec_portion",
        start: "top bottom", 
        end: "bottom top",    
        scrub: 1, 
      },
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cardData = [
    {
      title: "Mission",
      imageUrl: 'https://img.freepik.com/free-photo/business-success-ladder-background-climb-up-win_1017-50546.jpg',
      description: 'Our mission is to deliver scalable digital solutions that enhance operational efficiency, drive customer engagement, and foster long-term growth. We are committed to innovation, reliability, and customer satisfaction.',
    },
    {
      title: "Vision",
      imageUrl: 'https://img.freepik.com/free-photo/front-view-black-hourglass-dark-surface_179666-37754.jpg?uid=R160641874&ga=GA1.1.1692626744.1724665044&semt=ais_hybrid',
      description: 'To become the leading provider of innovative digital solutions in the B2C market, empowering businesses to achieve their full potential through cutting-edge technology and exceptional service.',
    },
    {
      title: "Strategy",
      imageUrl: 'https://img.freepik.com/free-photo/3d-rendering-arrow-hitting-target_23-2151266578.jpg',
      description: '• Technology Leadership\n• Customer-Centric Approach\n• Operational Excellence\n• Market Expansion\n• Partnerships and Alliances',
    }
  ];

  return (
    <div className='text-white min-h-screen'>
      {/* First Section with Background Image */}
      <div className='relative min-h-screen flex flex-col md:flex-row md:justify-start justify-end  md:items-end items-start gap-8 md:gap-0 md:px-20 px-6 py-32 overflow-hidden'>
        <div
          className='absolute inset-0 bg-cover bg-center filter blur-lg'
          style={{
            backgroundImage: `url(${Background})`,
          }}
        />
        <div className='absolute inset-0 bg-black opacity-50 z-0' />
        <h1 className='relative z-10 font-light md:text-4xl lg:text-8xl text-3xl text-left leading-tight'>
          Who We Are
        </h1>
        <p className='font-light font-josefin text-white z-20 md:px-20 md:text-xl text-base text-left'>
          At TAQNURA, we believe in driving innovation with purpose. Founded with a vision to empower businesses, we bring years of experience in crafting tailored digital solutions that address real-world challenges. Our mission is to deliver high-quality, scalable technology systems that enhance operational efficiency and improve customer engagement. Our team combines technical expertise with a deep understanding of industry trends, ensuring that our solutions are both cutting-edge and reliable.
        </p>
      </div>

      {/* Parallax Effect Section */}
      <div className='sec_portion min-h-screen pt-20'>
        <h1 className="md:text-4xl text-2xl font-light font-lato text-center w-full mb-10">The Way Forward</h1>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4'>
          {cardData.map((card, index) => (
            <Card
              key={index}
              imageUrl={card.imageUrl}
              title={card.title}
              description={card.description}
            />
          ))}
        </div>
      </div>
      <div className='px-6 md:px-14'>
        <Footer />
      </div>
    </div>
  );
}

function Card({ imageUrl, title, description }) {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className="card bg-cover bg-center min-h-[400px] flex flex-col items-start justify-between ps-10 py-10 relative overflow-hidden">
      {!isLoaded && <div className='absolute inset-0 bg-gray-200 animate-pulse' />} {/* Placeholder Skeleton */}
      <img
        src={imageUrl}
        alt={title}
        className="w-full h-full object-cover absolute inset-0"
        onLoad={() => setIsLoaded(true)}
        style={{ visibility: isLoaded ? 'visible' : 'hidden' }} // Hide image until loaded
      />
      <h1 className='font-josefin z-10 font-light text-xl md:text-2xl'>{title}</h1>
      <p className='font-josefin font-light md:text-xl text-base backdrop-blur-sm w-3/4 text-left whitespace-pre-line text-white'> {/* 'whitespace-pre-line' to handle bullet points and line breaks */}
        {description}
      </p>
    </div>
  );
}
