// import Navbar from './navbar';
import HomeContent from './homeContent';
// import BrandBadge from './brandbadge';
import GetKnow from './getknow';
import JoinNow from './joinNow';
import RoadMap from './roadmap';
import BoostCards from './boostingCards';
import Footer from './Footer';
import { useEffect } from 'react';
// import gsap from "gsap";
// import { useEffect } from 'react';


export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="relative overflow-hidden">
      {/* <Navbar /> */}
      <HomeContent />
      {/* <BrandBadge /> */}
      <GetKnow />
      <JoinNow />
      <RoadMap />
      {/* <BoostCards /> */}
      <div className='md:px-20 px-6 mt-36'> <Footer /></div>

    </div>
  );
}