import React, { useEffect } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { useMediaQuery } from "@mui/material";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function RoadMap() {
  const isMediumScreenOrLarger = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    const timelineItems = document.querySelectorAll(".timeline-item");

    // Store created ScrollTriggers for cleanup
    const scrollTriggers = [];

    const ctx = gsap.context(() => {
      timelineItems.forEach((item) => {
        const scrollTriggerInstance = gsap.fromTo(
          item,
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            scrollTrigger: {
              trigger: item,
              start: "top 80%",
              end: "top 50%",
              scrub: 1,
              // Optional markers for debugging
              // markers: true,
            },
          }
        );

        scrollTriggers.push(scrollTriggerInstance.scrollTrigger);
      });
    });

    // Refresh ScrollTrigger after animations are initialized
    ScrollTrigger.refresh();

    return () => {
      // Cleanup GSAP context
      ctx.revert();

      // Kill each individual ScrollTrigger instance
      scrollTriggers.forEach((st) => st.kill());

      // Refresh ScrollTrigger again to reset everything
      ScrollTrigger.refresh();
    };
  }, [isMediumScreenOrLarger]);

  return (
    <div className="text-white py-20 font-lato font-light">
      <h1 className="text-xl md:text-4xl mb-10">Road Map</h1>
      <Timeline position={isMediumScreenOrLarger ? "right" : "alternate"}>
        {isMediumScreenOrLarger ? (
          <>
            <TimelineItem className="timeline-item md:pt-5 md:pr-36 pb-0 md:ps-36">
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <div className="flex flex-col">
                <TimelineOppositeContent
                  className="text-blue-400 text-base md:text-xl"
                  sx={{ fontWeight: 300, fontFamily: "Lato, sans-serif" }}
                >
                  Vision
                </TimelineOppositeContent>
                <TimelineContent
                  sx={{
                    fontSize: "clamp(11px, 1.3vw, 15px)",
                    fontWeight: 300,
                    fontFamily: "Lato, sans-serif",
                  }}
                >
                  To become the leading provider of innovative digital solutions
                  in the B2C market, empowering businesses to achieve their full
                  potential through cutting-edge technology and exceptional
                  service.
                </TimelineContent>
              </div>
            </TimelineItem>
            <TimelineItem className="timeline-item md:pt-10 md:pr-36 pb-0 md:ps-36">
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <div className="flex flex-col">
                <TimelineOppositeContent
                  className="text-blue-400 text-base md:text-xl"
                  sx={{ fontWeight: 300, fontFamily: "Lato, sans-serif" }}
                >
                  Mission
                </TimelineOppositeContent>
                <TimelineContent
                  sx={{
                    fontSize: "clamp(11px, 1.3vw, 15px)",
                    fontWeight: 300,
                    fontFamily: "Lato, sans-serif",
                  }}
                >
                  Our Mission is to deliver scalable digital solutions that
                  enhance operational efficiency, drive customer engagement, and
                  foster long-term growth. We are committed to innovation,
                  reliability, and customer satisfaction.
                </TimelineContent>
              </div>
            </TimelineItem>
            <TimelineItem className="timeline-item md:pt-10 md:pr-36 pb-0 md:ps-36">
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <div className="flex flex-col">
                <TimelineOppositeContent
                  className="text-blue-400 text-base md:text-xl"
                  sx={{ fontWeight: 300, fontFamily: "Lato, sans-serif" }}
                >
                  Strategies
                </TimelineOppositeContent>
                <TimelineContent
                  sx={{
                    fontSize: "clamp(11px, 1.3vw, 15px)",
                    fontWeight: 300,
                    fontFamily: "Lato, sans-serif",
                  }}
                >
                  Technology leadership,
                  Customer-Centric Approach,
                  Operational Excellence,
                  Market Expansion,
                  Partnerships and Alliances

                  {/* </TimelineContent> */}
                </TimelineContent>
              </div>
            </TimelineItem>
            {/* <TimelineItem className="timeline-item md:pt-10 md:pr-36 pb-0 md:ps-36">
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <div className="flex flex-col">
                <TimelineOppositeContent
                  className="text-blue-400 text-base md:text-xl"
                  sx={{ fontWeight: 300, fontFamily: 'Lato, sans-serif' }}
                >
                  Milestones
                </TimelineOppositeContent>
                <TimelineContent
                  sx={{
                    fontSize: 'clamp(11px, 1.3vw, 15px)',
                    fontWeight: 300,
                    fontFamily: 'Lato, sans-serif',
                  }}
                >
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
                  labore aliquid eaque suscipit molestias. Ipsam, eveniet
                  quibusdam autem cumque provident eaque quam labore voluptatem
                  perferendis, dicta obcaecati, veniam laborum! Consequatur?
                </TimelineContent>
              </div>
            </TimelineItem> */}
          </>
        ) : (
          <>
            <TimelineItem className="timeline-item md:pt-5 md:pr-36 pb-0 md:ps-36">
              <TimelineOppositeContent
                className="text-blue-400 text-base md:text-xl"
                sx={{ fontWeight: 300, fontFamily: "Lato, sans-serif" }}
              >
                Vision
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  fontSize: "clamp(11px, 1.3vw, 15px)",
                  fontWeight: 300,
                  fontFamily: "Lato, sans-serif",
                }}
              >
                To become the leading provider of innovative digital solutions
                in the B2C market, empowering businesses to achieve their full
                potential through cutting-edge technology and exceptional
                service.
              </TimelineContent>
            </TimelineItem>
            <TimelineItem className="timeline-item md:pt-10 md:pr-36 pb-0 md:ps-36">
              <TimelineOppositeContent
                className="text-blue-400 text-base md:text-xl"
                sx={{ fontWeight: 300, fontFamily: "Lato, sans-serif" }}
              >
                Mission
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  fontSize: "clamp(11px, 1.3vw, 15px)",
                  fontWeight: 300,
                  fontFamily: "Lato, sans-serif",
                }}
              >
                Our Mission is to deliver scalable digital solutions that
                enhance operational efficiency, drive customer engagement, and
                foster long-term growth. We are committed to innovation,
                reliability, and customer satisfaction.
              </TimelineContent>
            </TimelineItem>
            <TimelineItem className="timeline-item md:pt-10 md:pr-36 pb-0 md:ps-36">
              <TimelineOppositeContent
                className="text-blue-400 text-base md:text-xl"
                sx={{ fontWeight: 300, fontFamily: "Lato, sans-serif" }}
              >
                Strategies
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  fontSize: "clamp(11px, 1.3vw, 15px)",
                  fontWeight: 300,
                  fontFamily: "Lato, sans-serif",
                }}
              >
                Technology Leadership
                <br />
                Customer-Centric Approach
                <br />
                Operational Excellence
                <br />
                Market Expansion
                <br />
                Partnerships and Alliances
              </TimelineContent>
            </TimelineItem>
            {/* <TimelineItem className="timeline-item md:pt-10 md:pr-36 pb-0 md:ps-36">
              <TimelineOppositeContent
                className="text-blue-400 text-base md:text-xl"
                sx={{ fontWeight: 300, fontFamily: 'Lato, sans-serif' }}
              >
                Milestones
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                sx={{
                  fontSize: 'clamp(11px, 1.3vw, 15px)',
                  fontWeight: 300,
                  fontFamily: 'Lato, sans-serif',
                }}
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
                labore aliquid eaque suscipit molestias. Ipsam, eveniet
                quibusdam autem cumque provident eaque quam labore voluptatem
                perferendis, dicta obcaecati, veniam laborum! Consequatur?
              </TimelineContent>
            </TimelineItem> */}
          </>
        )}
      </Timeline>
    </div>
  );
}
