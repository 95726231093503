import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../home/Footer';

export default function Solutions() {
  const cards = [
    {
      id: 1,
      title: "Custom ERP Implementations",
      image: "/assets/errp.jpg",
      description: "Our Custom ERP Implementation Solution helps businesses automate and manage their key processes with ERP systems."
    },
    {
      id: 2,
      title: "E-Commerce Ecosystem Solutions",
      image: "/assets/isometric.jpg",
      description: "Our E-Commerce Ecosystem Solution helps businesses not only sell products but also manage the entire lifecycle of online transactions."
    },
    {
      id: 3,
      title: "POS Solutions",
      image: "/assets/pos_Thumbnail.jpg",
      description: "Our POS Solution streamlines transactions, manages inventory, and tracks customer data for a seamless point-of-sale experience."
    },
    {
      id: 4,
      title: "Complete Digital Transformation",
      image: "/assets/internet.jpg",
      description: "Our Digital Transformation Solution is designed for businesses looking to modernize their operations and maximize efficiency across all departments."
    },
    {
      id: 5,
      title: "Mobile-First Business Solutions",
      image: "/assets/mobileapp.jpg",
      description: "For businesses that operate in dynamic environments, our Mobile-First Business Solutions are designed to ensure your teams."
    },
    {
      id: 6,
      title: "HR & Payroll Solutions",
      image: "/assets/hrms1.png",
      description: "Our HR & Payroll Solutions streamline human resource management and payroll processes for businesses of all sizes."
    }
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className='text-white min-h-screen pt-[100px] px-7 md:px-20'>
      <div className='md:px-12'>
        <h1 className='font-lato font-light text-xl sm:text-3xl lg:text-4xl'>Solutions</h1>
        <p className='md:mt-6 mt-4 font-light text-xs md:text-base text-justify sm:text-center font-josefin text-gray-300'>
          At TAQNURA, we understand that every business faces unique challenges. Our solutions are designed to provide comprehensive, integrated approaches that solve these challenges by combining multiple services into cohesive, tailor-made packages. Whether you need a full digital transformation or specialized systems to enhance your current operations, our solutions are crafted to drive real results.
        </p>
      </div>

      <div className='card_container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 md:gap-x-6 md:gap-y-12 gap-x-6 gap-y-16 mt-10 md:mt-24'>
        {cards.map((card) => (
          <Card key={card.id} card={card} />
        ))}
      </div>

      <Footer />
    </div>
  );
}

function Card({ card }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false); // Track image loading state
  const cardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Trigger animation once per scroll
        }
      },
      { threshold: 0.1 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={cardRef}
      className={`flex flex-col md:p-5 p-3 border border-gray-500 rounded-2xl md:pb-8 pb-5 bg-[rgba(217,217,217,0.1)] 
      transition-transform duration-700 ease-out
      ${isVisible ? 'transform translate-y-0 opacity-100' : 'transform translate-y-20 opacity-0'}`}
    >
      <div className="relative">
        {!isLoaded && (
          // Skeleton or placeholder
          <div className="animate-pulse bg-gray-200 h-[200px] md:h-[250px] lg:h-[300px] w-full rounded-xl"></div>
        )}
        <img
          src={card.image}
          alt={card.title}
          className={`rounded-xl w-full ${isLoaded ? 'block' : 'hidden'}`}
          onLoad={() => setIsLoaded(true)}
          style={{ maxHeight: '300px', objectFit: 'cover' }} // Adjust max height as per design
        />
      </div>
      <div className='flex flex-col gap-4 font-josefin font-light mt-6'>
        <h2 className='text-left'>{card.title}</h2>
        <p className='text-justify text-sm text-gray-300'>{card.description}</p>
        <Link to={`/solutions/${card.id}`} className="cta flex items-center self-end">
          <span className='font-light font-josefin text-sm'>learn more</span>
          <svg width="15px" height="10px" viewBox="0 0 13 10">
            <path d="M1,5 L11,5"></path>
            <polyline points="8 1 12 5 8 9"></polyline>
          </svg>
        </Link>
      </div>
    </div>
  );
}
